/* Social Login buttons by Davide Rizzo http://codepen.io/davidelrizzo/ */ 

.App {
  text-align: center;
}

.App-logo {
  width: 200px;
  margin-left: 50px;
  margin-right: 25px;
}

.App-tagline {
  text-align: bottom;
  color: darkgrey;
}

.App-header {
  background: url(/img/seatbg.jpg);
  background-size: cover; 
  padding: 20px;
  color: white;
}

.App-header-text {
  display: block;
  margin: 15px;
  padding: 12px;
  background-color: rgba(0,0,0,0.7);
  font-family: 'Quattrocento Sans', sans-serif;
}

.App-intro {
  font-size: large;
}

.modal-container {
  position: relative;
}
.modal-container .modal, .modal-container .modal-backdrop {
  position: absolute;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

p.tlabel {
    display: inline-block;
    margin: 6px 6px 6px 20px;
    font-variant: small-caps;
    font-size: 13px;
}

.daytile:hover {
	background-color: #BBDDDD;
    cursor: pointer
}

.timeslotOff {
    border: 1px solid #337ab7;
    color: #337ab7;
    border-radius: 5px;
    padding: 14px;
    margin: 5px;
    font-size: 18px;
    font-weight: bold;
    width: 450px;
    vertical-align: middle;
}

.timeslotAvail {
  border: 1px solid #557777;
  color: #efefef;
  background-color: #557777;
  border-radius: 5px;
  padding: 14px;
  margin: 5px;
  font-size: 18px;
  font-weight: bold;
  width: 450px;
  vertical-align: middle;
}

.timeslotSelected {
    border: 1px solid #557777;
    color: #666f7c;
    background-color: #BBDDDD;
    border-radius: 5px;
    padding: 14px;
    margin: 5px;
    font-size: 18px;
    font-weight: bold;
    width: 450px;
    vertical-align: middle;
}

.timeslotAvail:hover {
    background-color: #BBDDDD;
    color: #666f7c;
}

/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('/img/fbicon.png') 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
}
.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('/img/ggicon.png') 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}
