body {
  background: #172833;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.lighttext {
  color: #efefef;
}

.has-error .control-label,
.has-error .help-block,
.has-error .form-control-feedback {
    color: #CD716F;
}
