/**
 * index.scss
 * - Add any styles you want here!
 */

 body {
    background: #172833;
  }

  .quote {
    font-family: 'Times New Roman', Times, serif;
    font-size: large;
    font-style: italic;
    font-weight: bolder;
    margin: 5px 0px 5px 0px;
  }

  .circle {
    width: 36px;
    height: 36px;
    border: solid 4px #8faadc;
    text-align: center;
    border-radius: 50px;
    display: inline-block
  }
  
  .circle .label {
    margin-right: 0px;
    font-family: "News Yrok Times";
    font-weight: bold;
    padding: 3px 0px 2px 0px;
    color: #efefef;
  }
  
  
  .phone-number {
    color: #475863;
    font-family: Arial, Helvetica, sans-serif;
  }

  .stepstable {
    display: table;
  }

  .stepsrow {
    display: table-row;
  }

  .stepsicon {
    width: 150px;
    text-align: right;
    padding: 10px 10px 0px 0px;
    display: table-cell;
  }

  .stepscell {
    padding: 10px 10px 10px 0px;
    display: table-cell;
  }


  .inline {display: inline-block}
  .social { line-height: 1; }
  .wsite-social { vertical-align: middle; margin: 5px 0 5px 10px; line-height: 1; padding: 0; display: inline-block; }
  .wsite-social-item { width: 23px; height: 23px; margin: 0 0 0 3px; background-image:url(/img/social-blue.png); }
 
 
  .wsite-social-facebook {background-position:0 0;}
 .wsite-social-facebook:hover {background-position:0 -23px;}
 .wsite-social-facebook:active {background-position:0 -46px;}
 .wsite-social-pinterest {background-position:-23px 0;}
 .wsite-social-pinterest:hover {background-position:-23px -23px;}
 .wsite-social-pinterest:active {background-position:-23px -46px;}
 .wsite-social-twitter {background-position:-46px 0;}
 .wsite-social-twitter:hover {background-position:-46px -23px;}
 .wsite-social-twitter:active {background-position:-46px -46px;}
 .wsite-social-linkedin {background-position:-69px 0;}
 .wsite-social-linkedin:hover {background-position:-69px -23px;}
 .wsite-social-linkedin:active {background-position:-69px -46px;}
 .wsite-social-mail {background-position:-92px 0;}
 .wsite-social-mail:hover {background-position:-92px -23px;}
 .wsite-social-mail:active {background-position:-92px -46px;}
 .wsite-social-rss {background-position:-115px 0;}
 .wsite-social-rss:hover {background-position:-115px -23px;}
 .wsite-social-rss:active {background-position:-115px -46px;}
 .wsite-social-flickr {background-position:-138px 0;}
 .wsite-social-flickr:hover {background-position:-138px -23px;}
 .wsite-social-flickr:active {background-position:-138px -46px;}
 .wsite-social-plus {background-position:-161px 0;}
 .wsite-social-plus:hover {background-position:-161px -23px;}
 .wsite-social-plus:active {background-position:-161px -46px;}
 .wsite-social-vimeo {background-position:-184px 0;}
 .wsite-social-vimeo:hover {background-position:-184px -23px;}
 .wsite-social-vimeo:active {background-position:-184px -46px;}
 .wsite-social-yahoo {background-position:-207px 0;}
 .wsite-social-yahoo:hover {background-position:-207px -23px;}
 .wsite-social-yahoo:active {background-position:-207px -46px;}
 .wsite-social-youtube {background-position:-230px 0;}
 .wsite-social-youtube:hover {background-position:-230px -23px;}
 .wsite-social-youtube:active {background-position:-230px -46px;}